import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core/'

import { ReactComponent as LogoTEA } from 'assets/images/main-logo-tea.svg'
import { ReactComponent as LogoGE } from 'assets/images/main-logo-ge.svg'
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-back.svg'
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg'

import { useStyles } from './styles'
import { useStyleContext } from 'contexts/StyleContext/useStyleContext'
import { ConfigUtils } from 'utils/config'

interface HeaderProps {
  showRightButton?: boolean
  hideLogo?: boolean
  showBackButton?: boolean
}

const Header: React.FC<HeaderProps> = ({ showRightButton = false, hideLogo = false, showBackButton = true }) => {
  const classes = useStyles()
  const history = useHistory()

  const [logoUrl, setLogoUrl] = useState('')

  const { style, getFromStorage, isLoadingStyle } = useStyleContext()

  useEffect(() => {
    const applyLogo = async () => {
      if (style) {
        setLogoUrl(style.storeLogoUrl)
      } else {
        const styleUpdate = await getFromStorage()
        if (styleUpdate) setLogoUrl(styleUpdate.storeLogoUrl)
      }
    }
    applyLogo()
  }, [isLoadingStyle])

  const defaultLogo = () => {
    return ConfigUtils.isTeaConnection() ? (
      <LogoTEA height={50} width={400} className={classes.logo} />
    ) : (
      <LogoGE height={50} width={400} className={classes.logo} />
    )
  }

  //TODO: ocultar en resolucion de tablet y mobile
  return (
    <>
      <div className={classes.appBar} />
      <AppBar className={classes.appBar} elevation={0} position="absolute">
        <Toolbar className={classes.toolbar}>
          <IconButton color="primary" className={classes.backButton}>
            {showBackButton && (
              <ArrowBackIcon onClick={history.goBack} width={30} height={30} className={classes.backIcon} />
            )}
          </IconButton>
          {!hideLogo && (
            logoUrl ? (
              <div className={classes.logoContainer}>
                <img src={logoUrl} alt="Logo" className={classes.logo} />
              </div>
            ) : (
              defaultLogo()
            )
          )}
          <IconButton color="primary">
            {showRightButton ?? (
              <QuestionIcon width={30} height={30} className={classes.questionIcon} />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  )
}

export { Header }
