import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { MuiThemeProvider, StylesProvider, Theme, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import { setAuthHeader } from 'utils'
import { useApiCall, useLogOut, useLogIn } from 'hooks'
import { Admin, TokenLoginResponse } from 'types'
import { tokenLogin } from 'api'
import { AdminContext, StyleContext } from 'contexts'
import { SnackbarContainer, snackbar, ProtectedRoutes } from 'components'

import { Login } from './Login'
import { Home } from './Home'
import { Main } from './Main'
import { Colors, Style } from 'types/style'
import { useStyleContext } from 'contexts/StyleContext/useStyleContext'
import { ConfigUtils } from 'utils/config'

const App: React.FC = () => {
  const [isAppLoading, setIsAppLoading] = useState(true)
  const [isThemeLoading, setIsThemeLoading] = useState(true)

  const styleContextValue = useStyleContext()

  const [admin, setAdmin] = useState<Admin | undefined>(() => {
    const localAdmin = localStorage.getItem('admin')
    if (!localAdmin) return undefined
    return JSON.parse(localAdmin)
  })

  const [tokenLoginApi] = useApiCall<void, TokenLoginResponse>(tokenLogin)

  useEffect(() => {
    document.title = ConfigUtils.isTeaConnection() ? 'TEA Store' : 'GE Store'
    const favicon = ConfigUtils.isTeaConnection() ? '/favicon-tea.ico' : '/favicon-ge.ico'
    const faviconLink: HTMLLinkElement = document.querySelector(
      "link[rel*='icon']",
    )! as HTMLLinkElement
    faviconLink.href = faviconLink.href + favicon
    document.getElementsByTagName('head')[0].appendChild(faviconLink)
  }, [])

  const [logIn] = useLogIn()
  const [logOut] = useLogOut()

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      setIsAppLoading(false)
      return
    }

    const updateAdmin = async () => {
      try {
        setAuthHeader(token)
        const { user: updatedAdmin } = await tokenLoginApi()
        logIn(token, updatedAdmin)
      } catch (_) {
        logOut()
      } finally {
        setIsAppLoading(false)
      }
    }
    updateAdmin()
  }, [logIn, logOut, tokenLoginApi])

  const [theme, setTheme] = useState<Theme>(createMuiTheme())

  const { style, fetchAndSetStyle, isLoadingStyle } = useStyleContext()

  useEffect(() => {
    const applyTheme = (style: Style) => {
      const storeColors: Colors = style.storeColors
      const newTheme = createMuiTheme({
        palette: {
          primary: {
            main: storeColors.PRIMARY_MAIN, //COLORS.BASE
            contrastText: storeColors.PRIMARY_CONTRAST, //COLORS.WHITE,
          },
          secondary: {
            main: storeColors.SECONDARY_MAIN, //COLORS.ACCENT,
          },
          background: {
            default: storeColors.BACKGROUND,
          },
        },
      })
      setTheme(newTheme)
      setIsThemeLoading(false)
    }

    const updateTheme = async () => {
      if (style) {
        applyTheme(style)
      } else {
        await fetchAndSetStyle()
      }
    }

    updateTheme()
  }, [isLoadingStyle])

  return (
    <>
      {!isThemeLoading ? (
        <StylesProvider injectFirst>
        <StyleContext.Provider value={styleContextValue}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <AdminContext.Provider value={{ admin, setAdmin }}>
              <Router>
                <Switch>
                  <Route path="/login" component={Login} />
                  <ProtectedRoutes>
                    <Switch>
                      <Route path="/home" component={Home} />
                      <Main />
                    </Switch>
                  </ProtectedRoutes>
                </Switch>
              </Router>
              <SnackbarContainer
                ref={ref => {
                  snackbar.containerInstance = ref
                }}
              />
            </AdminContext.Provider>
          </MuiThemeProvider>
        </StyleContext.Provider>
        </StylesProvider>
      ) : null}
    </>
  )
}

export { App }
