import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  checkedIcon: {
    stroke: theme.palette.primary.contrastText,
    strokeWidth: 2,
  },
  uncheckedIcon: {
    stroke: theme.palette.primary.contrastText,
    strokeWidth: 2,
  },
}))

export { useStyles }