import { makeStyles } from '@material-ui/core/styles'

import { COLORS, FONTS } from 'styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    fontFamily: FONTS.GOTHAM_LIGHT,
    textTransform: 'inherit',
    marginTop: 10,
    width: 250,
    height: 60,
    fontSize: '18px',
    borderRadius: 10,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
    width: 400
  },
  logo: {
    maxHeight: 200,
    maxWidth: 400
  }
}))

export { useStyles }
