import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Container, Button, Grid, useTheme } from '@material-ui/core'

import { ReactComponent as LogoTEA } from 'assets/images/main-logo-tea.svg'
import { ReactComponent as LogoGE } from 'assets/images/main-logo-ge.svg'

import { useStyles } from './styles'
import { useStyleContext } from 'contexts/StyleContext/useStyleContext'
import { ConfigUtils } from 'utils/config'
import { Header } from 'components'
import { FONTS } from 'styles'

const Home: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()

  const [logoUrl, setLogoUrl] = useState('')

  const { style, getFromStorage, isLoadingStyle } = useStyleContext()

  useEffect(() => {
    const applyLogo = async () => {
      if (style) {
        setLogoUrl(style.storeLogoUrl)
      } else {
        const styleUpdate = await getFromStorage()
        if (styleUpdate) setLogoUrl(styleUpdate.storeLogoUrl)
      }
    }
    applyLogo()
  }, [isLoadingStyle])

  const defaultLogo = () => {
    return ConfigUtils.isTeaConnection() ? (
      <LogoTEA height={200} width={400} className={classes.logo} />
    ) : (
        <LogoGE height={200} width={400} className={classes.logo} />
    )
  }

  return (
    <Container className={classes.container} maxWidth={false}>
      <Header
        hideLogo={false}
        showRightButton={false}
        showBackButton={false}
      />
      <Grid container spacing={1} direction="column" justify="center" alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            onClick={() => history.push('reader')}
          >
            Escanear código QR
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            onClick={() => history.push('code')}
          >
            Ingresar código
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export { Home }
