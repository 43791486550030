import { Button, Container, Grid, List, Tab, Tabs, Typography, useTheme } from '@material-ui/core'
import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ReactComponent as ProfileLogo } from 'assets/images/profile.svg'
import { Header, snackbar } from 'components'
import { ERRORS, LOCAL_STORAGE } from 'consts'
import { useApiCall } from 'hooks'
import { COLORS, FONTS } from 'styles'

import { PrizeUser, User } from '../types'
import { createVisit } from './api'
import { Row, PosModal, RegisteredVisitModal } from './components'
import { useStyles } from './styles'
import { CreateVisitData } from './types'
import moment from 'moment'

interface DetailProps {
  getUserByQrCode: (code: string) => Promise<void>
  user?: User
}

const Detail: React.FC<DetailProps> = ({ user, getUserByQrCode }) => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const { code } = useParams()

  const [openPosModal, setOpenPosModal] = useState(false)
  const [openVisitModal, setOpenVisitModal] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const [selectedCoupons, setSelectedCoupons] = useState<number[]>([])
  const [selectedPrizes, setSelectedPrizes] = useState<number[]>([])

  const admin = localStorage.getItem(LOCAL_STORAGE.ADMIN)
  const adminJSON = admin ? JSON.parse(admin) : undefined

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue)
  }

  const onSelectCoupon = (id: number) => () => {
    setSelectedCoupons(
      selectedCoupons.includes(id)
        ? selectedCoupons.filter(coupon => coupon !== id)
        : selectedCoupons.concat(id),
    )
  }

  const onSelectPrize = (id: number) => () => {
    setSelectedPrizes(
      selectedPrizes.includes(id)
        ? selectedPrizes.filter(coupon => coupon !== id)
        : selectedPrizes.concat(id),
    )
  }

  const goToHome = () => {
    history.push('/home')
    window.location.reload()
  }

  const [createVisitApi] = useApiCall<CreateVisitData, undefined>(createVisit)

  useEffect(() => {
    const getUser = async () => {
      if (!user && code) {
        try {
          await getUserByQrCode(code)
        } catch (error) {
          const message =
            error.type === ERRORS.RESOURCE_NOT_FOUND_MESSAGE
              ? ERRORS.QR_NOT_FOUND_MESSAGE
              : ERRORS.GENERIC_ERROR_MESSAGE
          snackbar.show(message)
          history.push('/home')
          window.location.reload()
        }
      }
    }
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!user) return null

  const onSubmit = async () => {
    try {
      await createVisitApi({
        userId: user.id,
        coupons: selectedCoupons,
        prizes: selectedPrizes,
        adminId: adminJSON?.id,
      })

      if (selectedCoupons.length || selectedPrizes.length) {
        setOpenPosModal(true)
      } else {
        setOpenVisitModal(true)
      }
    } catch (error) {
      const customMessage = 'CUSTOM_MESSAGE:'
      if (error.message.includes(customMessage)) {
        const updatedMessage = error.message.replace(customMessage, '')
        snackbar.show(updatedMessage)
      } else if (error.message === ERRORS.VISIT_DURATION_ERROR_MESSAGE) {
        snackbar.show(ERRORS.VISIT_DURATION_ERROR_MESSAGE)
      } else {
        snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
      }
    }
  }

  const cleanPrizeTitle = (prizeUser: PrizeUser) => {
    // eslint-disable-next-line no-param-reassign
    prizeUser.prize.title = prizeUser.prize.title.replace(/(<([^>]+)>)/gi, '')
    return prizeUser
  }

  return (
    <>
      <Header
        hideLogo={false}
        showRightButton={false}
      />
      <Container className={classes.container} maxWidth="sm">
        <Grid className={classes.grid} container spacing={8}>
          {/* Nivel de loyalty y nombre del usuario */}
          <Grid xs={12} item container direction="column" alignItems="center" wrap="nowrap" className={classes.userGrid}>
            <Grid item>
              <Typography className={classes.loyaltyLevel} align="center"> {/* TODO: Cambiar por el color del nivel */}
                NIVEL {user.level ?? 1}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.name}>{user.fullname}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.lastVisit}>Última visita: <strong className={classes.lastVisitDate}>{user.lastVisit?.date ? moment(user.lastVisit.date).format('DD/MM/YYYY') : 'Sin visitas'}</strong></Typography>
              <Typography className={classes.storeName}>{user.lastVisit?.store}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.tabsGrid}>
            <Tabs value={activeTab} onChange={handleTabChange} className={classes.tabs} classes={{ indicator: classes.tabIndicator }}>
              <Tab label="Cupones activos" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
              <Tab label="Test" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
            </Tabs>

            <Grid item xs={12} className={classes.tabsContainer}>
              {activeTab === 0 && (
                <>
                  {/* Cupones activos */}
                  <Grid item xs={12} className={classes.couponsContainer}>
                    <List component="nav" disablePadding>
                      {user.coupons &&
                        user.coupons.map(({ coupon, id }) => (
                          <Row
                            key={id}
                            iconUrl={coupon.iconUrl}
                            title={coupon.title}
                            onSelect={onSelectCoupon(id)}
                            selected={selectedCoupons.includes(id)}
                          />
                        ))}
                      {user.prizes.map(cleanPrizeTitle).map(({ prize, id }) => (
                        <Row
                          key={id}
                          iconUrl={prize.iconUrl}
                          title={prize.title}
                          onSelect={onSelectPrize(id)}
                          selected={selectedPrizes.includes(id)}
                        />
                      ))}
                    </List>
                  </Grid>
                </>
              )}

              {activeTab === 1 && (
                <>
                  {/*Test results*/}
                  {user.userPoll?.sort((a, b) => a.order - b.order)
                    .map(({ question, answer }) => (
                      <>
                        <Grid item xs={12} className={classes.questionBox}>
                          <List component="nav" disablePadding>
                            <Typography className={classes.questionText}>{question}</Typography>
                            <Typography className={classes.answerText}><strong>R: {answer}</strong></Typography>
                          </List>
                        </Grid>
                      </>
                    ))}
                </>
              )}
            </Grid>

            {/* Botones de cancelar y canjear */}
            {activeTab === 0 && (
              <div className={classes.footer}>
                <Grid item container direction="row" alignItems="center" justify="center" className={classes.buttonsContainer}>
                  <Button
                    variant="outlined"
                    className={classes.cancelButton}
                    disableElevation
                    onClick={() => history.push('/home')}
                  >
                    Cancelar
                  </Button>

                  <Button
                    variant="contained"
                    className={classes.confirmButton}
                    color="primary"
                    disableElevation
                    onClick={onSubmit}
                  >
                    {selectedCoupons.length || selectedPrizes.length ? 'Canjear' : 'Completar'}
                  </Button>
                </Grid>
              </div>
            )}
          </Grid>
      </Grid>
      <PosModal open={openPosModal} onClose={goToHome} />
      <RegisteredVisitModal open={openVisitModal} onClose={goToHome} />
    </Container>
    </>)
}

export { Detail }
