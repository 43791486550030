import { lighten, makeStyles } from '@material-ui/core/styles'

import { COLORS, FONTS } from 'styles'

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingBottom: theme.spacing(10), // Add padding to prevent content from being hidden behind the footer
  },
  grid: {
    flex: 1,
    overflow: 'hidden',
    overflowX: 'hidden',
    padding: 25,
  },
  profilePicture: {
    width: 80,
    height: 80,
    borderRadius: '50%',
  },
  name: {
    fontFamily: FONTS.GOTHAM_MEDIUM,
    fontSize: '26px',
    lineHeight: 'normal',
    color: theme.palette.primary.contrastText,
    marginBottom: 5,
  },
  label: {
    fontFamily: FONTS.POPPINS_MEDIUM,
    fontSize: '0.7rem',
    color: theme.palette.primary.main,
  },
  loyaltyLevel: {
    color: theme.palette.primary.contrastText,
    fontFamily: FONTS.GOTHAM_REGULAR,
    fontSize: '16px',
    width: 103,
    borderRadius: 40,
    backgroundColor: '#FAC234', //TODO cambiar por color segun nivel
    alignSelf: 'center',
    marginBottom: 15,
  },
  lastVisit: {
    fontFamily: FONTS.GOTHAM_LIGHT,
    fontSize: '15px',
    lineHeight: 'normal',
    fontWeight: 400,
    color: theme.palette.primary.contrastText,
  },
  storeName: {
    textAlign: 'center',
    fontFamily: FONTS.GOTHAM_MEDIUM,
    fontSize: '15px',
    color: theme.palette.primary.contrastText,
  },
  lastVisitDate: {
    fontFamily: FONTS.GOTHAM_MEDIUM,
  },
  itemText: {
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    fontSize: '1rem',
    color: theme.palette.primary.main,
  },
  userGrid: {
    [theme.breakpoints.down(435)]: {
      paddingBottom: '0px !important'
    },
  },
  tabsGrid: {
    [theme.breakpoints.down(435)]: {
      padding: '10px !important'
    },
  },
  tabs: {
    marginBottom: 25
  },
  tabRoot: {
    padding: 0,
    fontFamily: FONTS.GOTHAM_BOLD,
    fontSize: '17px',
    color: theme.palette.primary.contrastText,
    textTransform: 'none',
  },
  tabIndicator: {
    backgroundColor: theme.palette.primary.contrastText,
  },
  tabSelected: {
    fontFamily: FONTS.GOTHAM_BOLD,
    fontSize: '17px',
    color: theme.palette.primary.contrastText,
  },
  tabsContainer: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 380px - 80px)', // Subtract footer height
    [theme.breakpoints.down(435)]: {
      maxHeight: 'calc(100vh - 380px - 80px)' // Subtract footer height
    },
  },
  couponsContainer: {
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down(435)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  cancelButton: {
    fontFamily: FONTS.GOTHAM_LIGHT,
    fontSize: '18px',
    textTransform: 'capitalize',
    marginRight: 10,
    width: '100%',
    maxWidth: '164px',
    height: '45px',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    [theme.breakpoints.down(435)]: {
      maxWidth: '100%',
      marginRight: 0,
      marginBottom: 10,
    },
  },
  confirmButton: {
    fontFamily: FONTS.GOTHAM_LIGHT,
    fontSize: '18px',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    width: '100%',
    maxWidth: '164px',
    height: '45px',
    borderRadius: '10px',
    color: theme.palette.background.default,
    [theme.breakpoints.down(435)]: {
      maxWidth: '100%',
    },
  },
  // Add new styles for the footer
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    zIndex: 1000,
  },
  listItem: {
    paddingLeft: 5,
  },
  questionBox: {
    padding: '16px',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: theme.palette.primary.contrastText,
    marginTop: '11px',
    backgroundColor: theme.palette.background.default,
  },
  questionText: {
    fontFamily: FONTS.GOTHAM_LIGHT,
    fontWeight: 400,
    marginBottom: '4px',
    color: theme.palette.primary.contrastText,
  },
  answerText: {
    fontFamily: FONTS.GOTHAM_MEDIUM,
    fontWeight: 500,
    color: theme.palette.primary.contrastText,
    fontStyle: 'italic',
  },
}))

export { useStyles }
