import React from 'react'

import { ReactComponent as CheckedIcon } from 'assets/icons/checked-item.svg'
import { ReactComponent as UncheckedIcon } from 'assets/icons/unchecked-item.svg'
import { COLORS } from 'styles'
import { useStyles } from './styles'

interface CheckboxProps {
  selected: boolean
  onSelect: () => void
}

const Checkbox: React.FC<CheckboxProps> = ({ selected, onSelect }) => {
  const classes = useStyles()

  return selected ? (
    <CheckedIcon onClick={onSelect} width={21} height={21} className={classes.checkedIcon} />
  ) : (
    <UncheckedIcon onClick={onSelect} width={21} height={21} className={classes.uncheckedIcon} />
  )
}


export { Checkbox }
