const FONTS = {
  POPPINS_BOLD: 'Poppins-Bold',
  POPPINS_REGULAR: 'Poppins-Regular',
  POPPINS_SEMI_BOLD: 'Poppins-SemiBold',
  POPPINS_MEDIUM: 'Poppins-Medium',
  REGATTIA_BOLD: 'Regattia-Bold',
  GOTHAM_REGULAR: 'Gotham-Regular',
  GOTHAM_MEDIUM: 'Gotham-Medium',
  GOTHAM_BOLD: 'Gotham-Bold',
  GOTHAM_LIGHT: 'Gotham-Light',
}

export { FONTS }
