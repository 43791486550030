import { makeStyles } from '@material-ui/core/styles'

import { COLORS, FONTS } from 'styles'

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    height: 120,
  },
  toolbar: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  backButton: {
    padding: 0,
    minWidth: 30,
    minHeight: 30,
  },
  backIcon: {
    stroke: theme.palette.background.default,
  },
  questionIcon: {
    stroke: theme.palette.primary.contrastText,
    fill: theme.palette.primary.contrastText,
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    fontSize: '1rem',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width: 400
  },
  logo: {
    fill: `${theme.palette.background.default} !important`,
    maxHeight: 50,
    maxWidth: 400,
    marginBottom: 5,
    [theme.breakpoints.down('md')]: {
      marginRight: 20,
      marginLeft: 20,
    }
  }
}))

export { useStyles }
